<template>
  <div>
    <!-- 위험등록부 목록 -->
    <c-table
      ref="table"
      title="LBL0001203"
      tableId="riskRegister"
      :columns="gridRisk.columns"
      :data="riskReduce.riskbooks" 
      :merge="gridRisk.merge"
      :gridHeight="setheight"
      :editable="editable&&!disabled"
      selection="multiple"
      rowKey="ramSignificantRiskRegisterId"
      checkDisableColumn="riskRegisterFlag"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="LBLREMOVE" icon="remove" @btnClicked="removeRisk" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="false"
            :requestContentsCols="requestContentsCols"
            tableKey="ramChecklistAssessScenarioId"
            ibmTaskTypeCd="ITT0000010"
            ibmTaskUnderTypeCd="ITTU000010"
            @imprChange="imprChange"
          />
        </template>
        <template v-else-if="col.name==='picture'">
          <q-btn 
            unelevated round dense
            size="7px"
            color="primary" 
            icon="wallpaper"
            @click.stop="openImprPicture(props)" />
        </template>
        <template v-else-if="col.name==='checkItemName'">
          <template v-if="props.row.checkItemId && props.row.ramCheckItemClassCd !== 'RCIC000015'">
            <!-- 작업, 기계·기구, 유해인자 & link 걸린 대상 -->
            <q-btn
              flat
              class="tableinnerBtn"
              align="left"
              color="blue-6"
              :label="props.row[col.name]"
              @click.stop="openItem(props.row)" />
          </template>
          <template v-else>
            <!-- 기타 & link 걸리지 않은 대상 -->
            {{props.row[col.name]}}
          </template>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'kpsr-risk-register',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
      }),
    },
    riskReduce: {
      type: Object,
      default: function() {
        return {
          imprs: [],
          riskbooks: [],
        }
      },
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      gridRisk: {
        merge: [
          { index: 0, colName: 'processCd' },
          { index: 1, colName: 'checkClassGroup' },
          { index: 2, colName: 'checkItemGroup' },
        ],
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'ramCheckItemClassName',
            field: 'ramCheckItemClassName',
            label: 'LBLCLASSIFICATION',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'checkItemName',
            field: 'checkItemName',
            // 평가대상
            label: 'LBL0001028',
            align: 'center',
            style: 'width:150px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'checklistQuestion',
            field: 'checklistQuestion',
            // 체크리스트 문항
            label: 'LBL0001092',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: 'LBL0001021',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'ramInspectResultName',
            field: 'ramInspectResultName',
            // 결과
            label: 'LBL0001007',
            align: 'center',
            style: 'width:90px',
          },
          {
            name: 'picture',
            field: 'picture',
            // 개선<br/>전/후<br/>사진
            label: 'LBL0001176',
            align: 'center',
            type: 'custom',
            style: 'width:50px',
            sortable: false,
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 추가 리스크관리 계획
            label: 'LBL0001122',
            align: 'left',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: 'LBL0001178',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: 'LBLIMPRNO_PROGRESS',
                align: 'center',
                style: 'width:130px',
                type: 'custom',
                sortable: false
              },
            ]
          },
        ],
        data: [],
        height: '500px'
      },
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      editable: true,
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 50) + 'px' : '500px'
    },
    disabled() {
      return this.param.ramStepCd === 'RRS0000015'
    },
    requestContentsCols() { 
      return ['ramCheckItemClassName', 'checkItemName'];
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.deleteUrl = this.$format(transactionConfig.ram.riskRegister.delete.url, 'ITT0000010')
      // code setting
      // list setting
    },
    research() {
      this.$emit('research');
    },
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      this.research();
    },
    /* eslint-disable no-unused-vars */
    removeRisk() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다. 
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            let deleteData = [];
            this.$_.forEach(selectData, item => {
              deleteData.push({
                ramSignificantRiskRegisterId: item.ramSignificantRiskRegisterId,
                ramRiskAssessmentPlanId: item.ramRiskAssessmentPlanId,
                processCd: item.processCd,
                scenarioId: item.ramChecklistAssessScenarioId,
              })
            })
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.riskReduce.riskbooks.splice(this.$_.findIndex(this.riskReduce.riskbooks, { ramSignificantRiskRegisterId: item.ramSignificantRiskRegisterId }), 1)
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
              this.research();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    openItem(row) {
      if (row.ramCheckItemClassCd === 'RCIC000001') {
        this.popupOptions.title = 'LBL0001093'; // 안전작업표준 상세
        this.popupOptions.param = {
          mdmSopId: row.checkItemId,
        };
        this.popupOptions.target = () => import(`${'@/pages/mdm/pim/sopDetail.vue'}`);
      } else if (row.ramCheckItemClassCd === 'RCIC000005') {
        this.popupOptions.title = "LBL0000956"; // 유해위험기계기구 상세
        this.popupOptions.param = {
          hhmHazardousMachineryId: row.checkItemId,
        };
        this.popupOptions.target = () => import(`${"@/pages/sop/mim/hazardEquipment/hazardEquipmentClassDetail.vue"}`);
      } else {
        this.popupOptions.title = 'LBL0001094'; // 유해인자 상세
        this.popupOptions.param = {
          hazardCd: row.checkItemId,
        };
        this.popupOptions.target = () => import(`${"@/pages/sai/wmr/harmfulFactorsDetail.vue"}`);
      }
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    openImprPicture(props) {
      this.popupOptions.title = 'LBL0001181'; // 개선 사진
      this.popupOptions.param = {
        ramChecklistAssessScenarioId: props.row.ramChecklistAssessScenarioId,
        sopImprovementIds: props.row.sopImprovementIds,
        disabled: this.disabled
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/checklist/action/checklistScenarioImprPicture.vue'}`);
      this.popupOptions.width = '30%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeScenarioImprPicturePopup;
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>